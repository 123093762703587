export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    collectors : null,
	collector : null,
},

getters : {
	collector: (state )=> state.collector,
	collectors: (state )=> state.collectors,
},


actions : {


	async getsearchedcollectors( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getsearchedcollectors')
		await axios.post("collectionmanager/manager/collectors/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setcollectors', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},

    async savecollector( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'savecollector')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collectors/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollector', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getcollector( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collectors/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollector', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

 async validatecollector( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collectors/validate/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				
			}
            resolve(response)
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject(error)
			commit('setloader', false)
		});
     });
				
	},
	async changecollectorstatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collectors/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollectors', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},




    async getcollectorlist( { commit }, page) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getcollectorlist')
		await axios.post("collectionmanager/manager/collectors?page="+ page)
		.then(response => {	
			if(response.data.res){
				commit('setcollectors', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},


    async updatecollector({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("collectionmanager/manager/collectors/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollector', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve(response)
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject(error)
			commit('setloader', false)
			
		});  
        });
    },


	async deletecollector({commit}, id){
        commit('setloader', id)
        return new Promise((resolve, reject) => {
        axios.post("collectionmanager/manager/collectors/delete/" + id)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollector', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve(response)
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject(error)
			commit('setloader', false)
			
		});  
        });
    },



},

mutations : {
	setcollector: (state, collector) => (state.collector = collector),
	setcollectors :(state,collectors ) => (state.collectors = collectors), 

},
}