import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import '@/axios'
import 'vue-select/dist/vue-select.css';

createApp(App).component('v-select', vSelect).use(store).use(router).mount('#app')
