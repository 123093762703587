export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    divisions : null,
	division : null,
},

getters : {
	division: (state )=> state.division,
	divisions: (state )=> state.divisions,
},


actions : {


	async getseracheddivisions( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getseracheddivisions')
		await axios.post("essentials/locations/divisions/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setdivisions', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},


    async savedivision( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'savedivision')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/locations/divisions/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setdivisions', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getdivision( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/divisions/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setdivision', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

	async deletedivision( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/divisions/delete/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setdivisions', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

	async changedivisionstatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/divisions/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setdivisions', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


    async getdivisionparishes( { commit }, {id, page}) {
            commit('setloader', id)
            commit('seterror', '')
            return new Promise((resolve, reject) => {
             axios.get("essentials/locations/divisions/getparishes/" + id + "?page="+ page)
            .then(response => {	
                if(response.data.res){
                    commit('setmsg', response.data.msg)
                    commit('setparishes', response.data.res)
                }
                resolve()
                commit('setloader', false)
            })
            .catch(error=> {
                if (error.response) {
                    commit('seterror', error.response.data.message)
                    commit('setloader', false)
                    if(error.response.data){
                        commit('seterror', error.response.data.message)
                    }
                }
                reject()
                commit('setloader', false)
            });
         });
                    
    },

    async getdivisionlist( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getdivisionlist')
		await axios.get("essentials/locations/divisions",  data)
		.then(response => {	
			if(response.data.res){
				commit('setdivisions', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},


    async updatedivision({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("essentials/locations/divisions/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setdivision', '')
                commit('setdivisions', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve()
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
			
		});  
        });
    },



},

mutations : {
	setdivision: (state, division) => (state.division = division),
	setdivisions :(state,divisions ) => (state.divisions = divisions), 

},
}