//import router from '@/router'
import store from '@/store'
  const adminguard = (to, from, next) => {
    if(localStorage.getItem('eocadessentialsaccessauthtoken') != null){
      if(store.getters.authuser != null ){
        if(store.getters.authuser.role == 'essentials'){
          return next()
        }else{
          return next({ name: 'login' })
        }
        }
      else{
        // router.push({ name: "welcome"})
        return next({ name: 'login' })
      }
    }
    else{
      return next({ name: 'login' })
    }
  };



  let routes = [
    { 
        path: "/",
        props: true,
      
        component: () => import("@/views/app/dash.vue"),
        title: 'Manage Essential of EOCAD ',
          children: [

            {
            path: "/",
              name: "welcome",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/home/welcome.vue"),
              meta: {
              title: ' Welcome to your EOCAD Collection Management Dashboard'
              }
            },


            {
              path: "/professions",
                name: "professions",
                beforeEnter: adminguard, 
                props:true,
                component: () => import("@/views/app/professions.vue"),
                meta: {
                title: 'Manage Professions'
                }
              },






            //collections
            {
              path: "/collections/users",
              name: "collections",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/collections/collections.vue"),
              meta: {
              title: 'Manage collections From Here'
              }
            },

            {
              path: "/collections/users/:id",
              name: "collectiondetails",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/collections/collectiondetails.vue"),
              meta: {
              title: 'Manage Country Districts From Here'
              }
            },

   //collectors
            {
              path: "/collections/collectors",
              name: "collectors",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/collections/collectors.vue"),
              meta: {
              title: 'Manage collections From Here'
              }
            },

            {
              path: "/collections/collector/:id",
              name: "collectordetails",
              beforeEnter: adminguard, 
              props:true,
              component: () => import("@/views/app/collections/collectordetails.vue"),
              meta: {
              title: 'Manage Country Districts From Here'
              }
            },




            // {
            //   path: "/locations/countries/district/:id",
            //   name: "districtcounties",
            //   beforeEnter: adminguard, 
            //   props:true,
            //   component: () => import("@/views/app/locations/districtcounties.vue"),
            //   meta: {
            //   title: 'Manage  District Counties From Here'
            //   }
            // },

            // {
            //   path: "/locations/countries/district/county/:id",
            //   name: "countydivisions",
            //   beforeEnter: adminguard, 
            //   props:true,
            //   component: () => import("@/views/app/locations/countydivisions.vue"),
            //   meta: {
            //   title: 'Manage  District Counties From Here'
            //   }
            // },

            // {
            //   path: "/locations/countries/district/county/division/:id",
            //   name: "divisionparishes",
            //   beforeEnter: adminguard, 
            //   props:true,
            //   component: () => import("@/views/app/locations/divisionparishes.vue"),
            //   meta: {
            //   title: 'Manage  District Counties From Here'
            //   }
            // },

            // {
            //   path: "/locations/countries/district/county/division/parish/:id",
            //   name: "parishvillages",
            //   beforeEnter: adminguard, 
            //   props:true,
            //   component: () => import("@/views/app/locations/parishvillages.vue"),
            //   meta: {
            //   title: 'Manage  District Counties From Here'
            //   }
            // },


          ]
        }
    ]


    export default routes;