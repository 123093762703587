export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    parishes : null,
	parish : null,
},

getters : {
	parish: (state )=> state.parish,
	parishes: (state )=> state.parishes,
},


actions : {


	async getserachedparishes( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getserachedparishes')
		await axios.post("essentials/locations/parishes/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setparishes', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},


    async saveparish( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'saveparish')
        return new Promise((resolve, reject) => {
		 axios.post("essentials/locations/parishes/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setparishes', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getparish( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/parishes/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setparish', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

	async deleteparish( { commit }, id) {
		commit('setloader', id)
		commit('seterror', '')
		return new Promise((resolve, reject) => {
		axios.get("essentials/locations/parishes/delete/" + id)
		.then(response => {	
			if(response.data.res){
				commit('setmsg', response.data.msg)
				commit('setparishes', response.data.res)
			}
			resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			reject()
			commit('setloader', false)
		});
	});
				
	},

    async changeparishstatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/locations/parishes/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setparishes', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},


	
    async getparishvillages( { commit }, {id, page}) {
            commit('setloader', id)
            commit('seterror', '')
            return new Promise((resolve, reject) => {
             axios.get("essentials/locations/parishes/getvillages/" + id + "?page="+ page)
            .then(response => {	
                if(response.data.res){
                    commit('setmsg', response.data.msg)
                    commit('setcounties', response.data.res)
                }
                resolve()
                commit('setloader', false)
            })
            .catch(error=> {
                if (error.response) {
                    commit('seterror', error.response.data.message)
                    commit('setloader', false)
                    if(error.response.data){
                        commit('seterror', error.response.data.message)
                    }
                }
                reject()
                commit('setloader', false)
            });
         });
                    
    },

    async getparishlist( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getparishlist')
		await axios.get("essentials/locations/parishes",  data)
		.then(response => {	
			if(response.data.res){
				commit('setparishes', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},


    async updateparish({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("essentials/locations/parishes/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setparish', '')
                commit('setparishes', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve()
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
			
		});  
        });
    },



},

mutations : {
	setparish: (state, parish) => (state.parish = parish),
	setparishes :(state,parishes ) => (state.parishes = parishes), 

},
}