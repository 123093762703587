import { createRouter, createWebHistory } from 'vue-router'
import authroutes from '@/router/adminroutes'
import mainroutes from '@/router/authroutes'

const baseRoutes = []
const routes = baseRoutes.concat(authroutes, mainroutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(`Navigating to: ${to.name}`);
//   next();
// });

router.beforeEach((to, from, next) => {
  const nearestWithTitle =to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
 let  VUE_APP_TITLE = "EOCAD Manager"
  let fulltitle = VUE_APP_TITLE + ' - ' + nearestWithTitle.meta.title
  let paramstitle = VUE_APP_TITLE + ' : ' + to.params.title

  if(to.params.title){
    document.title = paramstitle
  }else{
    document.title = fulltitle 
  }
  
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
  if(!nearestWithMeta) return next()
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  .forEach(tag => document.head.appendChild(tag))
 
  next()
})

export default router
