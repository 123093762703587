export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    genders : null,
	gender : null,
},

getters : {
	gender: (state )=> state.gender,
	genders: (state )=> state.genders,
},


actions : {


 
    async getgenderlist( { commit }) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getgenderlist')
		await axios.post("collector/open_collection/genders")
		.then(response => {	
			if(response.data.res){
				commit('setgenders', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},



},

mutations : {
	setgender: (state, gender) => (state.gender = gender),
	setgenders :(state,genders ) => (state.genders = genders), 

},
}