export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        authuser :'',
        authlocation : '',
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloader:'',
        isAuth:'',
        isVerified:'',
		collectionnum:'',
		statistics:'',
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloader: (state )=> state.pageloader,
		collectionnum: (state )=> state.collectionnum,
        isAuth: state => !!state.authuser,
		statistics: state => state.statistics,
    },


actions : {

    async loginuser( { commit }, data) {
		localStorage.removeItem('eocadessentialsaccessauthtoken');
		window.localStorage.clear();
		localStorage.clear();
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'login')
        return new Promise((resolve, reject) => {
		axios.post("auth/login",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('eocadessentialsaccessauthtoken', response.data.authtoken.accessToken)
				localStorage.setItem('userlocation', response.data.location)
				commit('setauthuser', response.data.res)
				commit('setauthlocation', response.data.location)
				router.push({ name: 'welcome' });
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async updateprofile( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updateaccount')
        return new Promise((resolve, reject) => {
		axios.post("auth/updateaccount",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('eocadessentialsaccessauthtoken', response.data.authtoken.accessToken)
				localStorage.setItem('userlocation', response.data.location)
				commit('setauthuser', response.data.res)
				commit('setauthlocation', response.data.location)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async updatepassword( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updatepassword')
        return new Promise((resolve, reject) => {
		axios.post("auth/updatepassword",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('eocadessentialsaccessauthtoken', response.data.authtoken.accessToken)
				localStorage.setItem('userlocation', response.data.location)
				commit('setauthuser', response.data.res)
				commit('setauthlocation', response.data.location)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });		
	},


	async getverifycode( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'getverifycode')
        return new Promise((resolve, reject) => {
		axios.post("auth/getverifycode",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				if(data.phone == response.data.res){
				commit('setcollectionnum', response.data.res)
				router.push({ name: 'updatelogins' });
				}else{
					commit('seterror', 'Something is wrong with this account')
				}
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });		
	},



	async updatepasswordbysms( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updatepasswordbysms')
        return new Promise((resolve, reject) => {
		axios.post("auth/updatepasswordbysms",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('eocadessentialsaccessauthtoken', response.data.authtoken.accessToken)
				localStorage.setItem('userlocation', response.data.location)
				commit('setauthuser', response.data.res)
				commit('setauthlocation', response.data.location)
				router.push({ name: 'welcome' });
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });		
	},




	async updateprofilepic( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updateimage')
        return new Promise((resolve, reject) => {
		axios.post("auth/updateprofilepic",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('eocadessentialsaccessauthtoken', response.data.authtoken.accessToken)
				localStorage.setItem('userlocation', response.data.location)
				commit('setauthuser', response.data.res)
				commit('setauthlocation', response.data.location)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},






	async logoutuser( { commit }) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'logout')
		commit('setauthuser','')
        return new Promise((resolve, reject) => {
		axios.get("auth/logout")
		.then(response => {	
			commit('setloader', false)
			if(response.data.msg){
			commit('setmsg', response.data.msg)
			localStorage.removeItem('eocadessentialsaccessauthtoken');
			window.localStorage.clear();
			localStorage.clear();
			router.push({ name: 'login' });	
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},



	async getstatistics( { commit }) {
        commit('setloader', '')
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.get("essentials/statistics")
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setstatistics', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

},

mutations : {
	setToken: (state, token) => (state.token = token),
	setauthuser :(state,authuser ) => (state.authuser = authuser), 
	setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
	setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
	setisVerified :(state,isVerified ) => (state.isVerified = isVerified),  
	setloader :(state,loading ) => (state.loading = loading), 
	setpageloader :(state,pageloader ) => (state.pageloader = pageloader), 
	seterror :(state,error ) => (state.error = error), 
	seterrors :(state,errors ) => (state.errors = errors), 
	setmsg :(state,msg ) => (state.msg = msg), 
	setapierror :(state,apierror ) => (state.apierror = apierror),
	setcollectionnum :(state,collectionnum ) => (state.collectionnum = collectionnum),
	setstatistics :(state,statistics ) => (state.statistics = statistics),
},
}