import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const ls = new SecureLs({isCompression:false});


import auth  from './modules/auth'
import country  from './modules/country.js'
import districts  from './modules/districts.js'
import counties  from './modules/counties.js'
import divisions  from './modules/divisions.js'
import parishes  from './modules/parishes.js'
import villages  from './modules/villages.js'
import proffessions  from './modules/proffessions.js'
import collections from './modules/collections'
import collectors from './modules/collectors.js'
import genders from './modules/genders.js'
export default createStore({
  modules: {
    auth,
    country,
    districts,
    counties,
    divisions,
    parishes,
    villages,
    proffessions,
    collections,
    collectors,
    genders

  },
    
  plugins: [createPersistedState(
    {
      key : 'www.collectionmanager.eocad.org',
      paths: ['auth'],
      storage:{
         getItem : (key) => ls.get(key),
         setItem: (key, value) => ls.set(key, value),
         removeItem: (key) => ls.remove(key)
      }
    }
  )],
  
})
