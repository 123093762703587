export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
 state : {
    collections : null,
	collection : null,
},

getters : {
	collection: (state )=> state.collection,
	collections: (state )=> state.collections,
},


actions : {

	async update_search_meta( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setloader', 'update_search_meta')
		await axios.post("collectionmanager/manager/collections/update_search_meta",  data)
		.then(response => {	
			if(response.data){
				commit('setmsg', response.data.msg)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},


	async getsearchedcollections( { commit }, data) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getsearchedcollections')
		await axios.post("collectionmanager/manager/collections/search",  data)
		.then(response => {	
			if(response.data.res){
				commit('setcollections', response.data.res)
			}
			commit('setpageloader', false)
			commit('setloader', false)
		})
		.catch(error=> {
			commit('setpageloader', false)
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
			commit('setloader', false)
		});
				
	},

    async savecollection( { commit }, data) {
		commit('seterror', '')
		commit('setloader', 'savecollection')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collections/create",  data)
		.then(response => {	
			
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollection', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

    async getcollection( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collections/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollection', response.data.res)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},

 async validatecollection( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collections/validate/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
				
			}
            resolve(response)
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject(error)
			commit('setloader', false)
		});
     });
				
	},
	async changecollectionstatus( { commit }, id) {
        commit('setloader', id)
		commit('seterror', '')
        return new Promise((resolve, reject) => {
		 axios.post("collectionmanager/manager/collections/status/" + id)
		.then(response => {	
			if(response.data.res){
                commit('setmsg', response.data.msg)
			}
            resolve()
			commit('setloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject()
			commit('setloader', false)
		});
     });
				
	},




    async getcollectionlist( { commit }, {data, page}) {
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getcollectionlist')
		await axios.post("collectionmanager/manager/collections?page="+ page, data)
		.then(response => {	
			if(response.data.res){
				commit('setcollections', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},

  async getcollectionlistbyuser( { commit }, {page, id}) {
	console.log(page, id)
		commit('seterror', '')
        commit('setmsg', '')
		commit('setpageloader', 'getcollectionlist')
		await axios.post("collectionmanager/manager/collections/byuser/"+ id +"?page="+ page)
		.then(response => {	
			if(response.data.res){
				commit('setcollections', response.data.res)
			}
			commit('setpageloader', false)
		})
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setpageloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
			commit('setpageloader', false)
		});
				
	},

    async updatecollection({commit}, {data, id}){
        const pid = ('slug', id);
        const pdata =('data', data)
        commit('setloader', pid)
        return new Promise((resolve, reject) => {
        axios.post("collectionmanager/manager/collections/update/" + pid, pdata)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollection', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve(response)
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject(error)
			commit('setloader', false)
			
		});  
        });
    },


	async deletecollection({commit}, id){
        commit('setloader', id)
        return new Promise((resolve, reject) => {
        axios.post("collectionmanager/manager/collections/delete/" + id)
        .then(response => {
            if(response.data.res){
                commit('setmsg', response.data.msg)
				commit('setcollection', response.data.res)
			}
            resolve()
			commit('setloader', false)
            resolve(response)
        })
		.catch(error=> {
			if (error.response) {
				commit('seterror', error.response.data.message)
				commit('setloader', false)
				if(error.response.data){
					commit('seterror', error.response.data.message)
				}
			}
            reject(error)
			commit('setloader', false)
			
		});  
        });
    },



},

mutations : {
	setcollection: (state, collection) => (state.collection = collection),
	setcollections :(state,collections ) => (state.collections = collections), 

},
}