import store from '@/store'
  const adminguard = (to, from, next) => {
    if(localStorage.getItem('eocadessentialsaccessauthtoken') != null){
      if(store.getters.authuser != null ){
        if(store.getters.authuser.role == 'admin'){
          return next()
        }else{
          return next({ name: 'login' })
        }
        }
      else{
        // router.push({ name: "welcome"})
        return next({ name: 'login' })
      }
    }
    else{
      return next({ name: 'login' })
    }
  };

let routes = [
    {
        path: "/login",
        name: "login", 
        props:true,
        component: () => import("@/views/auth/login.vue"),
        meta: {
        title: 'Login to EOCAD Essentials'
        }
    },


    {
        path: "/password-reset",
        name: "resetpassword", 
        props:true,
        component: () => import("@/views/auth/resetpassword.vue"),
        meta: {
        title: 'Reset Passoword to EOCAD Essentials'
        }
    },


    {
      path: "/updatelogins",
      name: "updatelogins", 
      props:true,
      component: () => import("@/views/auth/updatelogins.vue"),
      meta: {
      title: 'Update EOCAD Essentials Logins'
      }
  },


    {
        path: "/logout",
        name: "logout", 
        beforeEnter: adminguard, 
        props:true,
        component: () => import("@/views/auth/logout.vue"),
        meta: {
        title: 'Logout of EOCAD Essentials'
        }
    },



  

]
export default routes;